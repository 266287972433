@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
/**
 * Copyright 2020 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
}

/**
 * Copyright 2020 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
@font-face {
  font-family: FontAwesomeSolid;
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(/static/media/fa-solid-900.59edf72a.woff2) format("woff2"), url(/static/media/fa-solid-900.e615bbcb.ttf) format("truetype");
}
@font-face {
  font-family: FontAwesomeRegular;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(/static/media/fa-regular-400.0caf4c6c.woff2) format("woff2"), url(/static/media/fa-regular-400.0d03b1bb.ttf) format("truetype");
}
@font-face {
  font-family: FontAwesomeBrands;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(/static/media/fa-brands-400.ebb7a127.woff2) format("woff2"), url(/static/media/fa-brands-400.b823fc0d.ttf) format("truetype");
}
body,
input,
select,
button,
textarea {
  font-family: Lato, sans-serif;
  font-family: var(--font-text);
  font-size: 1.6rem;
  font-size: var(--text-100-size);
  line-height: 2.4rem;
  line-height: var(--text-100-line-height);
  font-weight: 400;
  font-weight: var(--font-normal);
  color: #333539;
  color: var(--black-100);
}

.title,
h1.title {
  font-family: Lato, sans-serif;
  font-family: var(--font-heading);
  font-weight: 700;
  font-weight: var(--font-bold);
  font-size: 7.2rem;
  font-size: var(--title-size);
  line-height: 7.8rem;
  line-height: var(--title-line-height);
}

.heading150,
h1 {
  font-family: Lato, sans-serif;
  font-family: var(--font-heading);
  font-weight: 300;
  font-weight: var(--font-light);
  font-size: 3.6rem;
  font-size: var(--heading-150-size);
  line-height: 4.2rem;
  line-height: var(--heading-150-line-height);
}

.heading100,
h2 {
  font-family: Lato, sans-serif;
  font-family: var(--font-heading);
  font-weight: 300;
  font-weight: var(--font-light);
  font-size: 2.8rem;
  font-size: var(--heading-100-size);
  line-height: 3.4rem;
  line-height: var(--heading-100-line-height);
}

.heading75,
h3 {
  font-family: Lato, sans-serif;
  font-family: var(--font-heading);
  font-weight: 400;
  font-weight: var(--font-normal);
  font-size: 2.4rem;
  font-size: var(--heading-75-size);
  line-height: 3rem;
  line-height: var(--heading-75-line-height);
}

.heading50,
h4 {
  font-family: Lato, sans-serif;
  font-family: var(--font-heading);
  font-weight: 400;
  font-weight: var(--font-normal);
  font-size: 2rem;
  font-size: var(--heading-50-size);
  line-height: 2.6rem;
  line-height: var(--heading-50-line-height);
}

.heading25,
h5 {
  font-family: Lato, sans-serif;
  font-family: var(--font-heading);
  font-weight: 700;
  font-weight: var(--font-bold);
  font-size: 1.6rem;
  font-size: var(--heading-25-size);
  line-height: 2.2rem;
  line-height: var(--heading-25-line-height);
}

.textNormal-100,
.textBold-100,
.textLight-100 {
  font-family: Lato, sans-serif;
  font-family: var(--font-text);
  font-size: 1.6rem;
  font-size: var(--text-100-size);
  line-height: 2.4rem;
  line-height: var(--text-100-line-height);
}

.textNormal-75,
.textBold-75,
.textLight-75 {
  font-family: Lato, sans-serif;
  font-family: var(--font-text);
  font-size: 1.2rem;
  font-size: var(--text-75-size);
  line-height: 1.6rem;
  line-height: var(--text-75-line-height);
}

.textNormal-50,
.textBold-50,
.textLight-50 {
  font-family: Lato, sans-serif;
  font-family: var(--font-text);
  font-size: 0.8rem;
  font-size: var(--text-50-size);
  line-height: 1.2rem;
  line-height: var(--text-50-line-height);
}

.textNormal-100,
.textNormal-75,
.textNormal-50 {
  font-weight: 400;
  font-weight: var(--font-normal);
}

.textBold-100,
.textBold-75,
.textBold-50 {
  font-weight: 700;
  font-weight: var(--font-bold);
}

.textLight-100,
.textLight-75,
.textLight-50 {
  font-weight: 300;
  font-weight: var(--font-light);
}

.textAccent {
  color: #E51777;
  color: var(--accent);
}

/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
.vgap-sm {
  margin-bottom: 0.8rem;
}

.vgap-md {
  margin-bottom: 1.6rem;
}

.vgap-lg {
  margin-bottom: 2.4rem;
}

.hgap-sm {
  margin-bottom: 0.8rem;
}

.hgap-md {
  margin-bottom: 1.6rem;
}

.hgap-lg {
  margin-bottom: 2.4rem;
}

.containerWrapper {
  display: flex;
  grid-gap: 2.4rem;
  gap: 2.4rem;
  grid-gap: var(--container-margin);
  gap: var(--container-margin);
  padding: 2.4rem 2.4rem;
  padding: var(--container-margin) var(--container-margin);
}

.container {
  margin: 2.4rem 2.4rem;
  margin: var(--container-margin) var(--container-margin);
  padding: 1.6rem 1.6rem;
  padding: var(--container-padding) var(--container-padding);
}

.bar {
  padding: 0.8rem 1.6rem;
  padding: var(--bar-padding-v) var(--bar-padding-h);
}

.static {
  border-radius: 2px;
  border-radius: var(--border-static);
}

.interactive {
  border-radius: 4px;
  border-radius: var(--border-interactive);
}
.interactive.disabled, .interactive:disabled {
  opacity: 0.3;
  cursor: auto;
}

/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
:root {
  /* 
   ###########################################################
   ########################## COLORS #########################
   ########################################################### */
  /* Primary */
  --primary-dark: #2e7370;
  --primary-med: #60bfbc;
  --primary-light: #99fffc;
  /* Secondary */
  --secondary-dark: #73462e;
  --secondary-med: #bf8160;
  --secondary-light: #ffbd99;
  /* Secondary */
  --accent: #E51777;
  /* Greys */
  --white: #ffffff;
  --grey-95: #f3f4f5;
  --grey-90: #e6e7e8;
  --grey-80: #ccced0;
  --grey-60: #999c9f;
  --grey-50: #808386;
  --black-150: #4d5053;
  --black-100: #333539;
  /* Signal */
  --signal-danger: #ff1100;
  --signal-warning: #ffcd00;
  --signal-confirm: #21a637;
  --signal-info: #0082ff;
  /* Status */
  --status-caution: #cc473d;
  --status-warning: #f2d661;
  --status-ok: #42a653;
  --status-info: #397dbf;
  --status-neutral: #babcbf;
  --status-light-caution: #efc5c2;
  --status-light-warning: #f9ecb8;
  --status-light-ok: #c8e9ce;
  --status-light-info: #c4d9ed;
  --status-light-neutral: #d7d8da;
  /* Shadows */
  --shadow-light: 0px 2px 5px rgba(0, 0, 0, 0.1);
  --shadow-med: 0px 2px 4px rgba(0, 0, 0, 0.2);
  --shadow-dark: 0px 1px 3px rgba(0, 0, 0, 0.4);
  /* Opacity */
  --opacity-disabled: 0.3;
  /* 
   ###########################################################
   ######################## TYPOGRAPHY #######################
   ########################################################### */
  --font-text: Lato, sans-serif;
  --font-heading: Lato, sans-serif;
  --font-bold: 700;
  --font-normal: 400;
  --font-light: 300;
  --title-size: 7.2rem;
  --heading-150-size: 3.6rem;
  --heading-100-size: 2.8rem;
  --heading-75-size: 2.4rem;
  --heading-50-size: 2rem;
  --heading-25-size: 1.6rem;
  --title-line-height: 7.8rem;
  --heading-150-line-height: 4.2rem;
  --heading-100-line-height: 3.4rem;
  --heading-75-line-height: 3rem;
  --heading-50-line-height: 2.6rem;
  --heading-25-line-height: 2.2rem;
  --text-100-size: 1.6rem;
  --text-75-size: 1.2rem;
  --text-50-size: 0.8rem;
  --text-100-line-height: 2.4rem;
  --text-75-line-height: 1.6rem;
  --text-50-line-height: 1.2rem;
  /* 
   ###########################################################
   ######################### SPACINGS ########################
   ########################################################### */
  --spacing-2: 0.2rem;
  --spacing-4: 0.4rem;
  --spacing-8: 0.8rem;
  --spacing-16: 1.6rem;
  --spacing-24: 2.4rem;
  --margin-sm: 0.8rem;
  --margin-md: 1.6rem;
  --margin-lg: 2.4rem;
  --padding-xs: 0.2rem;
  --padding-sm: 0.4rem;
  --padding-md: 0.8rem;
  --padding-lg: 1.6rem;
  --padding-xl: 2.4rem;
  --container-margin: 2.4rem;
  --container-padding: 1.6rem;
  --bar-padding-h: 1.6rem;
  --bar-padding-v: 0.8rem;
  --border-static: 2px;
  --border-interactive: 4px;
  --content-max-width: 960px;
}

.bepe-theme--light .bepe-default, .bepe-theme--light.bepe-default {
  color: #333539;
  color: var(--black-100);
  background-color: #f3f4f5;
  background-color: var(--grey-95);
}
.bepe-theme--light .bepe-default input, .bepe-theme--light.bepe-default input {
  color: #333539;
  color: var(--black-100);
  background-color: #f3f4f5;
  background-color: var(--grey-95);
}
.bepe-theme--light .bepe-default button, .bepe-theme--light.bepe-default button {
  color: #333539;
  color: var(--black-100);
}
.bepe-theme--light .bepe-default label, .bepe-theme--light.bepe-default label {
  color: #333539;
  color: var(--black-100);
}

.bepe-theme--dark .bepe-default, .bepe-theme--dark.bepe-default {
  color: #f3f4f5;
  color: var(--grey-95);
  background-color: #4d5053;
  background-color: var(--black-150);
}
.bepe-theme--dark .bepe-default input, .bepe-theme--dark.bepe-default input {
  color: #f3f4f5;
  color: var(--grey-95);
  background-color: #4d5053;
  background-color: var(--black-150);
}
.bepe-theme--dark .bepe-default button, .bepe-theme--dark.bepe-default button {
  color: #f3f4f5;
  color: var(--grey-95);
}
.bepe-theme--dark .bepe-default label, .bepe-theme--dark.bepe-default label {
  color: #f3f4f5;
  color: var(--grey-95);
}

/**
 * Copyright 2020 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
@-webkit-keyframes dissolve {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes dissolve {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes dissolveTranslate {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes dissolveTranslate {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@-webkit-keyframes dissolveTranslateDisabled {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    opacity: 0.3;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes dissolveTranslateDisabled {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    opacity: 0.3;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
/**
 * Copyright 2020 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
.center-box-100 {
  width: 100%;
}

.center-box,
.center-box-100 {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}

.hidden {
  display: none !important;
}

.status--caution {
  color: #ffffff;
  color: var(--white);
  background: #cc473d;
  background: var(--status-caution);
}

.status--warning {
  color: #333539;
  color: var(--black-100);
  background: #f2d661;
  background: var(--status-warning);
}

.status--info {
  color: #ffffff;
  color: var(--white);
  background: #397dbf;
  background: var(--status-info);
}

.status--ok {
  color: #ffffff;
  color: var(--white);
  background: #42a653;
  background: var(--status-ok);
}

.status--neutral {
  color: #333539;
  color: var(--black-100);
  background: #babcbf;
  background: var(--status-neutral);
}

.status-light--caution {
  color: #333539;
  color: var(--black-100);
  background: #efc5c2;
  background: var(--status-light-caution);
}

.status-light--warning {
  color: #333539;
  color: var(--black-100);
  background: #f9ecb8;
  background: var(--status-light-warning);
}

.status-light--info {
  color: #333539;
  color: var(--black-100);
  background: #c4d9ed;
  background: var(--status-light-info);
}

.status-light--ok {
  color: #333539;
  color: var(--black-100);
  background: #c8e9ce;
  background: var(--status-light-ok);
}

.status-light--neutral {
  color: #333539;
  color: var(--black-100);
  background: var(--status-neutral-light);
}

.status-text--caution {
  color: #cc473d;
  color: var(--status-caution);
}

.status-text--warning {
  color: #f2d661;
  color: var(--status-warning);
}

.status-text--info {
  color: #397dbf;
  color: var(--status-info);
}

.status-text--ok {
  color: #42a653;
  color: var(--status-ok);
}

.status-text--neutral {
  color: #babcbf;
  color: var(--status-neutral);
}

.status-text-light--caution {
  color: #efc5c2;
  color: var(--status-light-caution);
}

.status-text-light--warning {
  color: #f9ecb8;
  color: var(--status-light-warning);
}

.status-text-light--info {
  color: #c4d9ed;
  color: var(--status-light-info);
}

.status-text-light--ok {
  color: #c8e9ce;
  color: var(--status-light-ok);
}

.status-text-light--neutral {
  color: var(--status-neutral-light);
}

/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
.bepe-button {
  display: flex;
  justify-content: center;
  grid-gap: 1.6rem;
  gap: 1.6rem;
  margin: 0;
  border-width: 0px;
  border-style: none;
  outline: none;
  transition: color 0.2s ease, background-color 0.2s ease, -webkit-filter 0.2s ease;
  transition: color 0.2s ease, background-color 0.2s ease, filter 0.2s ease;
  transition: color 0.2s ease, background-color 0.2s ease, filter 0.2s ease, -webkit-filter 0.2s ease;
  text-align: center;
  cursor: pointer;
}
.bepe-button:disabled {
  opacity: 0.3;
  cursor: auto;
}
.bepe-button.bepe-button--busy {
  opacity: 0.7;
}
.bepe-button--medium {
  font-size: 1.6rem;
  font-size: var(--text-100-size);
  line-height: 2.4rem;
  line-height: var(--text-100-line-height);
  height: calc(2.4rem + 0.8rem * 2);
  height: calc(var(--margin-lg) + var(--padding-md) * 2);
  padding: 0.8rem calc(1.6rem);
  padding: var(--padding-md) calc(var(--padding-lg));
}
.bepe-button--small {
  font-size: 1.2rem;
  font-size: var(--text-75-size);
  line-height: 1.6rem;
  line-height: var(--text-75-line-height);
  height: calc(1.6rem + 0.8rem * 2);
  height: calc(var(--margin-md) + var(--padding-md) * 2);
  padding: 0.8rem calc(1.6rem);
  padding: var(--padding-md) calc(var(--padding-lg));
  grid-gap: 0.8rem;
  gap: 0.8rem;
}
.bepe-button:enabled:hover {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}
.bepe-button:enabled:active {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}
.bepe-button--primary {
  background-color: #60bfbc;
  background-color: var(--primary-med);
  color: #ffffff;
  color: var(--white);
}
.bepe-button--secondary {
  background-color: #ccced0;
  background-color: var(--grey-80);
  color: #333539;
  color: var(--black-100);
}
.bepe-button--tertiary {
  background-color: #f3f4f5;
  background-color: var(--grey-95);
  color: #333539;
  color: var(--black-100);
  border: 1px solid #999c9f;
  border: 1px solid var(--grey-60);
}
.bepe-button--accent {
  background-color: #E51777;
  background-color: var(--accent);
  color: #ffffff;
  color: var(--white);
}
.bepe-button--danger {
  background-color: #ff1100;
  background-color: var(--signal-danger);
  color: #ffffff;
  color: var(--white);
}
.bepe-button--danger:enabled:hover {
  background-color: #FF4133;
}

/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
.bepe-form-element {
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}
.bepe-form-element--with-label {
  padding-top: 1.8rem;
}
.bepe-form-element > label {
  cursor: default;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-weight: var(--font-bold);
  grid-gap: 0.8rem;
  gap: 0.8rem;
  grid-gap: var(--margin-sm);
  gap: var(--margin-sm);
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  transition: all 0.1s ease-in-out;
  color: #999c9f;
  color: var(--grey-60);
  font-size: 1.2rem;
  font-size: var(--text-75-size);
  line-height: 1.6rem;
  line-height: var(--text-75-line-height);
}
.bepe-form-element__hint {
  font-size: 1.2rem;
  font-size: var(--text-75-size);
  line-height: 1.6rem;
  line-height: var(--text-75-line-height);
  color: #999c9f;
  color: var(--grey-60);
  margin-top: 0.2rem;
  margin-top: var(--spacing-2);
  padding-left: 1px;
}
.bepe-form-element > *:first-child:not(:disabled):focus + label {
  color: #E51777;
  color: var(--accent);
}
.bepe-form-element.bepe-form-element--danger > :first-child:not(:focus) {
  border-bottom-color: #ff1100;
  border-bottom-color: var(--signal-danger);
}
.bepe-form-element.bepe-form-element--danger > :first-child:not(:focus), .bepe-form-element.bepe-form-element--danger > :first-child:not(:focus) ~ label, .bepe-form-element.bepe-form-element--danger > :first-child:not(:focus) ~ .bepe-form-element__hint {
  color: #ff1100;
  color: var(--signal-danger);
}
.bepe-form-element.bepe-form-element--warning > :first-child:not(:focus) {
  border-bottom-color: #ffcd00;
  border-bottom-color: var(--signal-warning);
}
.bepe-form-element.bepe-form-element--warning > :first-child:not(:focus), .bepe-form-element.bepe-form-element--warning > :first-child:not(:focus) ~ label, .bepe-form-element.bepe-form-element--warning > :first-child:not(:focus) ~ .bepe-form-element__hint {
  color: #ffcd00;
  color: var(--signal-warning);
}
.bepe-form-element.bepe-form-element--confirm > :first-child:not(:focus) {
  border-bottom-color: #21a637;
  border-bottom-color: var(--signal-confirm);
}
.bepe-form-element.bepe-form-element--confirm > :first-child:not(:focus), .bepe-form-element.bepe-form-element--confirm > :first-child:not(:focus) ~ label, .bepe-form-element.bepe-form-element--confirm > :first-child:not(:focus) ~ .bepe-form-element__hint {
  color: #21a637;
  color: var(--signal-confirm);
}
.bepe-form-element.bepe-form-element--info > :first-child:not(:focus) {
  border-bottom-color: #0082ff;
  border-bottom-color: var(--signal-info);
}
.bepe-form-element.bepe-form-element--info > :first-child:not(:focus), .bepe-form-element.bepe-form-element--info > :first-child:not(:focus) ~ label, .bepe-form-element.bepe-form-element--info > :first-child:not(:focus) ~ .bepe-form-element__hint {
  color: #0082ff;
  color: var(--signal-info);
}

* .bepe-form-element > label, * .bepe-form-element > .bepe-form-element__hint, *.bepe-form-element > label, *.bepe-form-element > .bepe-form-element__hint,
.bepe-theme--light .bepe-form-element > label,
.bepe-theme--light .bepe-form-element > .bepe-form-element__hint,
.bepe-theme--light.bepe-form-element > label,
.bepe-theme--light.bepe-form-element > .bepe-form-element__hint,
.bepe-theme--dark .invert .bepe-form-element > label,
.bepe-theme--dark .invert .bepe-form-element > .bepe-form-element__hint,
.bepe-theme--dark .invert.bepe-form-element > label,
.bepe-theme--dark .invert.bepe-form-element > .bepe-form-element__hint {
  color: #999c9f;
  color: var(--grey-60);
}

.bepe-theme--dark .bepe-form-element > label, .bepe-theme--dark .bepe-form-element > .bepe-form-element__hint, .bepe-theme--dark.bepe-form-element > label, .bepe-theme--dark.bepe-form-element > .bepe-form-element__hint,
.bepe-theme--light .invert .bepe-form-element > label,
.bepe-theme--light .invert .bepe-form-element > .bepe-form-element__hint,
.bepe-theme--light .invert.bepe-form-element > label,
.bepe-theme--light .invert.bepe-form-element > .bepe-form-element__hint {
  color: #ccced0;
  color: var(--grey-80);
}

/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
.bepe-textfield {
  text-align: left;
  width: 20rem;
  position: relative;
}
.bepe-textfield input {
  padding: 0.8rem 1.6rem;
  padding: var(--padding-md) var(--padding-lg);
  border-radius: 4px;
  border-radius: var(--border-interactive);
  border-style: none;
  border-bottom: 1px solid #ccced0;
  border-bottom: 1px solid var(--grey-80);
  outline: none;
  width: 100%;
  background-color: #ffffff;
  background-color: var(--white);
  color: #333539;
  color: var(--black-100);
}
.bepe-textfield input::-webkit-input-placeholder {
  color: #999c9f;
  color: var(--grey-60);
}
.bepe-textfield input::placeholder {
  color: #999c9f;
  color: var(--grey-60);
}
.bepe-textfield input:not(:disabled):focus {
  border-bottom: 1px solid #E51777;
  border-bottom: 1px solid var(--accent);
}
.bepe-textfield input:not(:disabled):focus::-webkit-input-placeholder {
  opacity: 0;
}
.bepe-textfield input:not(:disabled):focus::placeholder {
  opacity: 0;
}
.bepe-textfield input[value=""] {
  padding-right: 1.6rem;
  padding-right: var(--padding-lg);
  background-color: red;
}
.bepe-textfield input[value=""] ~ .bepe-textfield__delete-button {
  opacity: 0;
  visibility: hidden;
}
.bepe-textfield__container {
  position: relative;
}
.bepe-textfield__delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 1.6rem;
  padding: 0 var(--padding-lg);
  height: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.bepe-textfield__delete-button:hover {
  opacity: 0.7;
}
.bepe-textfield--with-empty-button input {
  padding-right: 3.6rem;
}

input:disabled {
  opacity: 0.3;
}
input:disabled ~ .bepe-textfield__delete-button {
  opacity: 0.3;
  cursor: default;
}

* .bepe-textfield input, *.bepe-textfield input,
.bepe-theme--light .bepe-textfield input,
.bepe-theme--light.bepe-textfield input,
.bepe-theme--dark .invert .bepe-textfield input,
.bepe-theme--dark .invert.bepe-textfield input {
  background-color: #ffffff;
  background-color: var(--white);
  color: #333539;
  color: var(--black-100);
  border-bottom: 1px solid #ccced0;
  border-bottom: 1px solid var(--grey-80);
}

.bepe-theme--dark .bepe-textfield input, .bepe-theme--dark.bepe-textfield input,
.bepe-theme--light .invert .bepe-textfield input,
.bepe-theme--light .invert.bepe-textfield input {
  background-color: #333539;
  background-color: var(--black-100);
  color: #f3f4f5;
  color: var(--grey-95);
  border-bottom: 1px solid #808386;
  border-bottom: 1px solid var(--grey-50);
}

/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
.bepe-uploadfield {
  border: 2px dashed #fff;
  height: 500px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 2.4rem;
  gap: 2.4rem;
  grid-gap: var(--spacing-24);
  gap: var(--spacing-24);
  background-color: #2d2f2f;
  padding: 2.4rem 2.4rem;
  padding: var(--spacing-24) var(--spacing-24);
  color: #fff;
}
.bepe-uploadfield.bepe-uploadfield--active {
  background-color: #717677;
}

/**
 * Inspired from https://cdpn.io/ingomc/fullembedgrid/ONrMqe?animations=run&type=embed
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
.bepe-spinner {
  display: inline-block;
  position: relative;
  width: 10rem;
  height: 10rem;
  font-size: 1.6rem;
  background-color: transparent;
  box-sizing: border-box;
}
.bepe-spinner * {
  box-sizing: inherit;
}
.bepe-spinner--hidden {
  visibility: hidden;
  opacity: 0;
}
.bepe-spinner__circle {
  width: 100%;
  height: 100%;
  -webkit-animation-name: anim-spinner;
          animation-name: anim-spinner;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.bepe-spinner__circle-wrapper {
  width: 100%;
  height: 50%;
  overflow: hidden;
}
.bepe-spinner__circle-wrapper--bottom {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.bepe-spinner__circle-wrapper--bottom .bepe-spinner__circle-inner {
  -webkit-animation-name: anim-spinner-inner-2;
          animation-name: anim-spinner-inner-2;
}
.bepe-spinner__circle-inner {
  width: 100%;
  height: 200%;
  border: 0.1em solid #2e7370;
  border: 0.1em solid var(--primary-dark);
  border-bottom: 0.1em solid transparent;
  border-right: 0.1em solid transparent;
  border-radius: 50%;
  -webkit-animation-name: anim-spinner-inner-1;
          animation-name: anim-spinner-inner-1;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-timing-function: cubic-bezier(0.25, 0.1, 0.5, 1);
          animation-timing-function: cubic-bezier(0.25, 0.1, 0.5, 1);
}

* .bepe-spinner__circle-inner, *.bepe-spinner__circle-inner,
.bepe-theme--light .bepe-spinner__circle-inner,
.bepe-theme--light.bepe-spinner__circle-inner,
.bepe-theme--dark .invert .bepe-spinner__circle-inner,
.bepe-theme--dark .invert.bepe-spinner__circle-inner {
  border-left-color: #2e7370;
  border-left-color: var(--primary-dark);
  border-top-color: #2e7370;
  border-top-color: var(--primary-dark);
}

.bepe-theme--dark .bepe-spinner__circle-inner, .bepe-theme--dark.bepe-spinner__circle-inner,
.bepe-theme--light .invert .bepe-spinner__circle-inner,
.bepe-theme--light .invert.bepe-spinner__circle-inner {
  border-left-color: #ffffff;
  border-left-color: var(--white);
  border-top-color: #ffffff;
  border-top-color: var(--white);
}

@-webkit-keyframes anim-spinner {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes anim-spinner {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes anim-spinner-inner-1 {
  from {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  to {
    -webkit-transform: rotate(205deg);
            transform: rotate(205deg);
  }
}
@keyframes anim-spinner-inner-1 {
  from {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  to {
    -webkit-transform: rotate(205deg);
            transform: rotate(205deg);
  }
}
@-webkit-keyframes anim-spinner-inner-2 {
  from {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  to {
    -webkit-transform: rotate(-115deg);
            transform: rotate(-115deg);
  }
}
@keyframes anim-spinner-inner-2 {
  from {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  to {
    -webkit-transform: rotate(-115deg);
            transform: rotate(-115deg);
  }
}
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
.bepe-badge {
  color: var(--black);
  font-weight: 400;
  font-weight: var(--font-normal);
  margin: 0;
  padding: 0.2rem 0.8rem;
  padding: var(--spacing-2) var(--spacing-8);
  background-color: #babcbf;
  background-color: var(--status-neutral);
  text-align: center;
  box-sizing: border-box;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.bepe-badge--caution {
  color: #ffffff;
  color: var(--white);
  background: #cc473d;
  background: var(--status-caution);
}
.bepe-badge--warning {
  color: #333539;
  color: var(--black-100);
  background: #f2d661;
  background: var(--status-warning);
}
.bepe-badge--info {
  color: #ffffff;
  color: var(--white);
  background: #397dbf;
  background: var(--status-info);
}
.bepe-badge--ok {
  color: #ffffff;
  color: var(--white);
  background: #42a653;
  background: var(--status-ok);
}
.bepe-badge--neutral {
  color: #ffffff;
  color: var(--white);
  background: #babcbf;
  background: var(--status-neutral);
}
.bepe-badge--100 {
  font-size: 1.6rem;
  font-size: var(--text-100-size);
  line-height: 2.4rem;
  line-height: var(--text-100-line-height);
}
.bepe-badge--75 {
  font-size: 1.2rem;
  font-size: var(--text-75-size);
  line-height: 1.6rem;
  line-height: var(--text-75-line-height);
}
.bepe-badge--50 {
  font-size: 0.8rem;
  font-size: var(--text-50-size);
  line-height: 1.2rem;
  line-height: var(--text-50-line-height);
}

/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
.bepe-icon {
  line-height: 1;
}
.bepe-icon.bepe-icon--solid {
  font-family: FontAwesomeSolid;
}
.bepe-icon.bepe-icon--regular {
  font-family: FontAwesomeRegular;
}
.bepe-icon.bepe-icon--brands {
  font-family: FontAwesomeBrands;
}

/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
.bepe-backdrop {
  display: block;
  position: fixed;
  z-index: 1000;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.bepe-backdrop.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bepe-backdrop > * {
  cursor: initial;
}

* .bepe-backdrop, *.bepe-backdrop,
.bepe-theme--light .bepe-backdrop,
.bepe-theme--light.bepe-backdrop,
.bepe-theme--dark .invert .bepe-backdrop,
.bepe-theme--dark .invert.bepe-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.bepe-theme--dark .bepe-backdrop, .bepe-theme--dark.bepe-backdrop,
.bepe-theme--light .invert .bepe-backdrop,
.bepe-theme--light .invert.bepe-backdrop {
  background-color: rgba(255, 255, 255, 0.5);
}

/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
.bepe-radiobutton {
  width: 100%;
}
.bepe-radiobutton input {
  display: none;
}
.bepe-radiobutton input:disabled ~ label {
  cursor: initial;
  opacity: 0.3;
  opacity: var(--opacity-disabled);
}
.bepe-radiobutton label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1.6rem;
  gap: 1.6rem;
  grid-gap: var(--padding-lg);
  gap: var(--padding-lg);
  background-color: #ffffff;
  background-color: var(--white);
  border: 1px solid #ccced0;
  border: 1px solid var(--grey-80);
  padding: 0.8rem 1.6rem;
  padding: var(--padding-md) var(--padding-lg);
  cursor: pointer;
}
.bepe-radiobutton--selected label {
  border-color: #E51777;
  border-color: var(--accent);
}
.bepe-radiobutton__selected-icon {
  color: #E51777;
  color: var(--accent);
}
.bepe-radiobutton__unselected-icon {
  border: 1px solid #999c9f;
  border: 1px solid var(--grey-60);
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
}
.bepe-radiobutton--selected .bepe-radiobutton__unselected-icon {
  display: none;
}
.bepe-radiobutton--unselected .bepe-radiobutton__selected-icon {
  display: none;
}

.bepe-theme--dark .bepe-radiobutton label, .bepe-theme--dark.bepe-radiobutton label,
.bepe-theme--light .invert .bepe-radiobutton label,
.bepe-theme--light .invert.bepe-radiobutton label {
  background-color: #333539;
  background-color: var(--black-100);
  border: 1px solid #808386;
  border: 1px solid var(--grey-50);
}
.bepe-theme--dark .bepe-radiobutton--selected label, .bepe-theme--dark.bepe-radiobutton--selected label,
.bepe-theme--light .invert .bepe-radiobutton--selected label,
.bepe-theme--light .invert.bepe-radiobutton--selected label {
  border-color: #E51777;
  border-color: var(--accent);
}
.bepe-theme--dark .bepe-radiobutton__unselected-icon, .bepe-theme--dark.bepe-radiobutton__unselected-icon,
.bepe-theme--light .invert .bepe-radiobutton__unselected-icon,
.bepe-theme--light .invert.bepe-radiobutton__unselected-icon {
  border: 1px solid #ccced0;
  border: 1px solid var(--grey-80);
}

/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
/**
 * Copyright 2022 by Benjamin Petry (www.bpetry.de).
 * This software is provided on an "AS IS" BASIS,
 * without warranties or conditions of any kind, either express or implied.
 */
.bepe-radiogroup {
  width: -webkit-max-content;
  width: max-content;
}
.bepe-radiogroup .bepe-radiogroup__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  grid-gap: var(--margin-sm);
  gap: var(--margin-sm);
  padding: 0.4rem 0 0.2rem 0;
  padding: var(--padding-sm) 0 var(--spacing-2) 0;
}
.bepe-radiogroup__element {
  display: flex;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  grid-gap: var(--padding-md);
  gap: var(--padding-md);
  align-items: center;
}
